<template>
    <div class="pro6Com3">
        <div class="p63Box">
            <!-- 标题 -->
            <div class="p63Title">主要云服务产产品</div>
            <!-- 导航 -->
            <div class="p63Tabs">
                <ul>
                    <li class="p63Li" @mousemove="showP63s(1)">
                        <img src="https://iv.vu818.com/img/icon1.png" alt="" class="p63Icon" />
                        <p class="p63P">云服务器部署</p>
                    </li>
                    <li class="p63Li" @mousemove="showP63s(2)">
                        <img src="https://iv.vu818.com/img/icon2.png" alt="" class="p63Icon" />
                        <p class="p63P">云数据库</p>
                    </li>
                    <li class="p63Li" @mousemove="showP63s(3)">
                        <img src="https://iv.vu818.com/img/icon3.png" alt="" class="p63Icon" />
                        <p class="p63P">存储与CDN</p>
                    </li>
                    <li class="p63Li" @mousemove="showP63s(4)">
                        <img src="https://iv.vu818.com/img/icon4.png" alt="" class="p63Icon" />
                        <p class="p63P">网络与负载均衡</p>
                    </li>
                    <li class="p63Li" @mousemove="showP63s(5)">
                        <img src="https://iv.vu818.com/img/icon5.png" alt="" class="p63Icon" />
                        <p class="p63P">DDoS高防IP</p>
                    </li>
                    <li class="p63Li" @mousemove="showP63s(6)">
                        <img src="https://iv.vu818.com/img/icon6.png" alt="" class="p63Icon" />
                        <p class="p63P">云监控</p>
                    </li>
                </ul>
            </div>
            <!-- 内容 -->
            <div class="p63Data" v-show="showP63 === 1">
                <!-- 左图 -->
                <div class="p63Left">
                    <img src="http://iv.vu818.com/img/pc631.png" alt="" />
                </div>
                <!-- 右文 -->
                <div class="p63Right">
                    <p class="p63RBig">云服务器部署</p>
                    <p class="p63RSmall">
                        云服务器 ECS (Elastic Compute Service) 是处 理能力可弹性伸缩的计算服务，它的管理方式比物理服务器更简单高效。无需提
                        前采购投入，您可以根据业务的需要，随时创建实例、扩容磁盘、或释放任意多台云 服务器实例。 云服务器 ECS 实例（以下简称 ECS
                        实例）是一个虚拟的计算环境，包含 CPU、内存等最基础的计算组件，是云服务器呈献给每个用户的实际操作实体。ECS 实例是云服务器
                        最为核心的概念，其他的资源，比如磁盘、IP、镜像、快照等，只有与 ECS 实例结合后才能使用。 下图是云服务器 ECS
                        实例的示意图。您可以通过云服务器管理控制台配置您的 ECS 实例， 例如选择实例规格、磁盘、操作系统、带宽、和安全组等资源。
                    </p>
                </div>
            </div>
            <div class="p63Data" v-show="showP63 === 2">
                <!-- 左图 -->
                <div class="p63Left">
                    <img src="http://iv.vu818.com/img/pc632.png" alt="" />
                </div>
                <!-- 右文 -->
                <div class="p63Right">
                    <p class="p63RBig">云数据库</p>
                    <p class="p63RSmall">
                        支持PB级数据存储的分布式关系型数据库。它支持MySQL数据库接口，采用可扩展的分布式体系，
                        稳定可靠的高可用架构，深度定制优化的软硬件方案，为企业用户提供了一个兼顾高容量、低成本、高可用、高性能的分布式数据库。
                    </p>
                </div>
            </div>
            <div class="p63Data" v-show="showP63 === 3">
                <!-- 左图 -->
                <div class="p63Left">
                    <img src="http://iv.vu818.com/img/pc633.png" alt="" />
                </div>
                <!-- 右文 -->
                <div class="p63Right">
                    <p class="p63RBig">存储与CDN加速</p>
                    <p class="p63RSmall">
                        威有云对象存储（Object Storage
                        Service，简称OSS），是威有云对外提供的海量，安全，低成本，高可靠的云存储服务。用户可以通过调用API，在任何应用、任何时间、任何地点上传和下载数据，也可以通过用户Web控制台对数据进行简单的管理。OSS适合存放任意文件类型，适合各种网站、开发企业及开发者使用。
                    </p>
                </div>
            </div>
            <div class="p63Data" v-show="showP63 === 4">
                <!-- 左图 -->
                <div class="p63Left">
                    <img src="http://iv.vu818.com/img/pc634.png" alt="" />
                </div>
                <!-- 右文 -->
                <div class="p63Right">
                    <p class="p63RBig">网络与负载均衡</p>
                    <p class="p63RSmall">
                        负载均衡（Server Load
                        Balancer）是对多台云服务器进行流量分发的负载均衡服务。负载均衡可以通过流量分发扩展应用系统对外的服务能力，通过消除单点故障提升应用系统的可用性。<br />负载均衡服务通过设置虚拟服务地址（IP），将位于同一地域（Region）的多台云服务器（Elastic
                        Compute
                        Service，简称ECS）资源虚拟成一个高性能、高可用的应用服务池；根据应用指定的方式，将来自客户端的网络请求分发到云服务器池中。<br />
                        负载均衡服务会检查云服务器池中ECS的健康状态，自动隔离异常状态的ECS，从而解决了单台ECS的单点问题，同时提高了应用的整体服务能力。在标准的负载均衡功能之外，负载均衡服务还具备TCP与HTTP抗DDoS攻击的特性，增强了应用服务器的防护能力。
                    </p>
                </div>
            </div>
            <div class="p63Data" v-show="showP63 === 5">
                <!-- 左图 -->
                <div class="p63Left">
                    <img src="http://iv.vu818.com/img/pc635.png" alt="" />
                </div>
                <!-- 右文 -->
                <div class="p63Right">
                    <p class="p63RBig">DDoS高防IP</p>
                    <p class="p63RSmall">
                        DDoS高防IP产品是针对互联网服务器（包括非威有云主机）在遭受大流量的DDoS攻击后导致服务不可用的情况，推出的付费增值服务。您可以通过配置DDoS高防IP，将攻击流量引流到高防IP，确保源站的稳定可靠。<br />
                        DDoS攻击防护峰值带宽 20 Gbps ~ 600 Gbps ，最低威有16,800 / 月（20G）。同时，提供按天弹性付费方案，按当天攻击规模灵活付费。
                    </p>
                </div>
            </div>
            <div class="p63Data" v-show="showP63 === 6">
                <!-- 左图 -->
                <div class="p63Left">
                    <img src="http://iv.vu818.com/img/pc636.png" alt="" />
                </div>
                <!-- 右文 -->
                <div class="p63Right">
                    <p class="p63RBig">云监控</p>
                    <p class="p63RSmall">
                        云监控服务可用于收集获取威有云资源的监控指标或用户自定义的监控指标，探测服务可用性，以及针对指标设置警报。使您全面了解威有云上的资源使用情况、业务的运行状况和健康度，并及时收到异常报警做出反应，保证应用程序顺畅运行。
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pro6Com3',
    data() {
        return {
            showP63: 1,
        };
    },
    methods: {
        showP63s(i) {
            this.showP63 = i;
        },
    },
};
</script>

<style lang="less">
.pro6Com3 {
    min-width: 1423px;
    height: 850px;
    margin-top: 50px;
    text-align: left;
    .p63Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .p63Title {
            width: 40%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 30px;
            font-weight: 900;
            margin: 0 auto;
        }
        // 导航
        .p63Tabs {
            width: 100%;
            height: 150px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: space-around;
                .p63Li {
                    cursor: pointer;
                    .p63Icon {
                        // width: 100%;
                        height: 100px;
                        transition: 0.5s;
                    }
                    .p63P {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-weight: 900;
                        font-size: 18px;
                    }
                }
            }
        }
        .p63Li:hover {
            .p63Icon {
                transform: scale(1.1);
            }
            .p63P {
                color: #7781f1;
            }
        }
        // 内容
        .p63Data {
            width: 100%;
            height: 600px;
            margin-top: 20px;
            // background: linear-gradient(to right, #7781f1, #7781f1e0);
            border-radius: 20px;
            // 左图
            .p63Left {
                width: 45%;
                height: 100%;
                float: left;
                padding-top: 100px;
                box-sizing: border-box;
            }
            // 右文
            .p63Right {
                width: 45%;
                height: 100%;
                float: right;
                margin-right: 50px;
                .p63RBig {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 40px;
                    font-weight: 900;
                    margin-top: 150px;
                    margin-right: 20px;
                }
                .p63RSmall {
                    width: 100%;
                    line-height: 30px;
                }
            }
        }
    }
}
</style>
